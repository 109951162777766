<template>
  <div class="member-box">
    <div class="head-filter">
      <el-cascader
        v-if="tenantVersion === 'school'"
        v-model="PeriodData"
        :options="CampusAndPeriod.campuses"
        :props="{
          value: 'id',
          label: 'name',
        }"
        placeholder="学段"
      >
      </el-cascader>
      <el-cascader
        v-if="tenantVersion === 'school'"
        v-model="CampusData"
        :options="CampusAndPeriod.periods"
        :props="{
          value: 'id',
          label: 'name',
        }"
        placeholder="校区"
      >
      </el-cascader>
      <el-cascader
        v-if="tenantVersion === 'school'"
        v-model="EntranceYearsData"
        :options="EntranceYears"
        :props="{
          value: 'value',
          label: 'label',
        }"
        placeholder="毕业年份"
      >
      </el-cascader>
      <!-- ! -->
      <el-cascader
        v-model="positionIds"
        :options="OrganizationList"
        :props="props"
        placeholder="架构/会内职务"
      >
      </el-cascader>
      <el-input
        
        v-model="filterForm.keyword"
        placeholder="请输入会员名称"
        @change="handleFilter"
      >
        <i class="el-icon-search el-input__icon" slot="suffix"></i>
      </el-input>
      <el-button type="primary" @click="refreshList">刷新</el-button>
    </div>
    <div class="member-list" style="margin-top: 35px">
      <el-table
        :data="memberList"
        style="width: 100%"
        :height="250"
        ref="multipleTable"
        :highlight-current-row="!selectType"
        @row-click="handleCurrentChange"
        @selection-change="handleSelectionChange"
        v-loading="loading"
        v-loadmore="LoadMore"
        class="table"
      >
        <!-- 多选 -->
        <el-table-column
          ref="selection"
          fixed
          type="selection"
          width="70"
          :selectable="selectable"
          v-if="selectType"
          :key="Math.random()"
        ></el-table-column>
        <!-- 单选 -->
        <el-table-column
          fixed
          width="70"
          v-if="!selectType"
          :key="Math.random()"
        >
          <template slot-scope="scope">
            <el-radio
              :label="scope.row"
              v-model="currentRow"
              :disabled="
                selectedMembers.findIndex(
                  (item) => item.user_id === scope.row.id
                ) !== -1
              "
            >
              {{ "&nbsp" }}</el-radio
            >
          </template>
        </el-table-column>
        <template v-for="(th, index) in thead">
          <el-table-column
            :prop="th.prop"
            :label="th.label"
            :width="th.width || ''"
            :key="index"
          >
            <template slot-scope="{ row }">
              <p style="white-space: pre-wrap">
                {{ row[th.prop] | placeholder }}
              </p>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
  </div>
</template>

<script>
import {
  SearchMemberList,
  positionLevel,
  getEntranceYears,
  getCampusAndPeriod,
} from "@/modules/organization-admin/api/member/member-list";

export default {
  props: {
    // 已选择会员数据
    Members: {
      type: Array,
      default: () => [],
    },
    // 0:单选/1:多选模式
    selectType: {
      type: Number,
      default: 1,
    },
    update: {
      type: Function,
    },
    // 当前选中索引
    currentIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      EntranceYears: [],
      EntranceYearsData: [],
      CampusAndPeriod: [],
      CampusData: [],
      PeriodData: [],
      loading: false, //加载
      saveLoading: false,
      thead: [
        { label: "会员名称", prop: "nickname" },
        { label: "会内职务", prop: "title_name" },
        // { label: "班级信息", prop: "class_info" },
      ],
      memberList: [],
      //筛选对象
      filterForm: {
        hash_user_id: 0,
        organization_id: "",
        position_id: "",
        keyword: "", //关键词
        page_size: 10, //每页多少条数据
        page: 1,
      },
      getable: true, //是否可以请求列表
      // 联级选择配置
      props: {
        value: "id",
        label: "name",
        expandTrigger: "hover",
        children: "position",
      },
      currentRow: "", // 单选时选中的数据项
      positionIds: [],
      PreOrganizations: [],
      OrganizationList: [],
      selectArr: [], //勾选的数组id
    };
  },
  mounted() {
    if (this.tenantVersion === "school") {
      this.thead.push({ label: "班级", prop: "class_info" });
    }
    this.getParentToList();
  },
  methods: {
    getSchoolFilterList() {
      // TODO: 刷新
      getEntranceYears()
        .then((res) => {
          this.EntranceYears = [];
          const { data } = res;
          for (let i = 0; i < data.length; i++) {
            this.EntranceYears.push({
              label: data[i],
              value: data[i],
            });
          }
        })
        .catch((err) => {});
      getCampusAndPeriod()
        .then((res) => {
          const { data } = res;
          this.CampusAndPeriod = data;
        })
        .catch((err) => {});
    },
    // 获取会员列表
    getSearchMemberList(pageData) {
      if (this.getable) {
        this.loading = true;
        SearchMemberList(pageData)
          .then((res) => {
            const { data } = res;
            // for (let i = 0; i < data.data.length; i++) {
            //   data.data[i].class_info = data.data[i].class_info.replace("\n", "<br />");
            // }
            if (pageData.page === 1) {
              this.memberList = data.data;
            } else {
              this.memberList = [...this.memberList, ...data.data];
            }
            if (data.data.length < this.filterForm.page_size) {
              this.getable = false;
            }
            this.filterForm.page++;
            this.loading = false;
          })
          .catch((err) => {});
      }
    },
    // 获取架构列表
    getParentToList() {
      this.getSchoolFilterList();
      positionLevel({ is_list_tree: 0, is_show_all: 1 })
        .then((res) => {
          console.log("positionLevel", res);
          const { data } = res;
          data.map((item) => {
            item.children = [];
          });
          this.OrganizationList = data;
          console.log(data);
        })
        .catch((err) => {});
    },
    // 判断是否可选
    selectable(row, index) {
      if (
        this.selectedMembers.findIndex(
          (item) => item.user_id === row.user_id
        ) !== -1
      ) {
        return false;
      } else {
        return true;
      }
    },
    // 筛选操作
    handleFilter(e, key) {
      const fliterData = { ...this.filterForm };
      fliterData.organization_id =
        fliterData.organization_id === "" ? -1 : fliterData.organization_id;
      fliterData.position_id =
        fliterData.position_id === "" ? -1 : fliterData.position_id;
      fliterData.page = key ? fliterData.page : 1;
      this.getable = true;
      this.getSearchMemberList(fliterData);
    },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = [];
      } else {
        this.selectArr = [];
        selection.forEach((el) => {
          this.selectArr.push(el.user_id);
        });
      }
      console.log(this.selectArr);
      this.$emit("select", this.selectArr);
    },
    // 点击数据项操作
    handleCurrentChange(val) {
      this.currentRow = val;
      if (
        this.selectedMembers.findIndex(
          (item) => item.user_id === val.user_id
        ) !== -1
      ) {
        return;
      } else {
        this.memberList.forEach((element) => {
          if (val.user_id === element.user_id) {
            this.$nextTick(() => {
              this.$refs.multipleTable.toggleRowSelection(element);
            });
          }
        });
      }
    },
    //触底刷新
    LoadMore() {
      if (this.getable) {
        this.handleFilter(this.filterForm, "Load");
      }
    },
    // 刷新列表
    refreshList() {
      this.getable = true;

      this.filterForm = {
        hash_user_id: 0,
        organization_id: "",
        position_id: "",
        keyword: "", //关键词
        page_size: 10, //每页多少条数据
        page: 1,
      };
      this.getSearchMemberList(this.filterForm);
    },
    // 确定
    submit() {
      if (!this.currentRow) {
        this.$message.info("请选择数据项");
      } else {
        this.saveLoading = true;
        const data = {
          user_id: this.currentRow.id,
          activity_id: this.$route.params.id,
          type: 0,
        };
      }
    },
    // 取消
    cancel(formName) {
      this.openDialog = false;
    },
    // openDialogFn() {
    //   const data = { ...this.filterForm };
    //   this.getSearchMemberList(data);
    //   this.getParentToList();
    // },
  },
  created() {
    const data = { ...this.filterForm };
    this.getSearchMemberList(data);
    this.getParentToList();
  },

  watch: {
    positionIds(val) {
      this.filterForm.position_id = val[val.length - 1]
        ? val[val.length - 1]
        : -1;
      this.filterForm.organization_id = val[0] ? val[0] : -1;
      this.handleFilter(this.filterForm);
    },
    EntranceYearsData(val) {
      this.filterForm.entrance_year = val[val.length - 1]
        ? val[val.length - 1]
        : -1;
      this.handleFilter(this.filterForm);
    },
    CampusData(val) {
      this.filterForm.campus_id = val[val.length - 1]
        ? val[val.length - 1]
        : "";
      this.handleFilter(this.filterForm);
    },
    PeriodData(val) {
      this.filterForm.period_id = val[val.length - 1]
        ? val[val.length - 1]
        : "";
      this.handleFilter(this.filterForm);
    },
    memberList: {
      deep: true,
      handler(val) {
        this.memberList.forEach((el) => {
          if (
            this.selectArr.findIndex((elem) => elem === el.user_id) !== -1 &&
            this.$refs.multipleTable
          ) {
            this.$nextTick(() => {
              this.$refs.multipleTable.toggleRowSelection(el, true);
            });
          }
        });
      },
    },
  },
  computed: {
    tenantVersion() {
      return this.$store.getters.tenant.version;
    },
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
    selectedMembers: {
      get() {
        console.log(this.Members);
        return this.Members;
      },
      set(val) {
        // this.$emit("changeSelect", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.member-box {
  position: relative;
}
.check-post {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.head-filter {
  width: 100%;
  display: flex;
  justify-content: space-around;

  .el-select,
  .el-cascader,
  .el-input {
    // flex: 1;
    // margin-right: 10px;
  }
}

.member-list {
  ::v-deep.el-table__row {
    cursor: pointer;
  }
}
// .table {
//   white-space: pre-line !important;

//   .cell {
//     white-space: pre-line !important;
//   }
// }

.el-table .cell {
  white-space: pre-line;
}
</style>
