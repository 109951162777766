<template>
  <div class="body" v-loading="loading">
    <div class="content-box">
      <el-tabs
        v-model="activeTab"
        @tab-click="changeTab"
        :before-leave="beforeHandle"
      >
        <el-tab-pane label="创建活动" name="create">
          <span
            slot="label"
            class="tabs-label"
            :class="[activeTab === 'create' ? 'active' : '']"
            >创建活动</span
          >
          <div class="create-activity">
            <div class="preview-box" @click.stop="() => {}">
              <iframe
                name="activityFrame"
                id="activityFrame"
                src=""
                frameborder="0"
                width="100%"
                height="100%"
                scrolling="yes"
                ref="iframe"
              ></iframe>
              <!-- <div class="iframe-mask"></div> -->
            </div>
            <div class="activity-form">
              <DetailOne
                ref="activityBaseForm"
                v-model="form"
                :positionOptions="positionOptions"
              />
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="报名规划" name="join">
          <span
            slot="label"
            class="tabs-label"
            :class="[activeTab === 'join' ? 'active' : '']"
            >报名规划</span
          >
          <DetailJoin ref="activityJoinForm" v-model="joinForm" />
        </el-tab-pane>
        <el-tab-pane label="活动信息" name="info">
          <span
            slot="label"
            class="tabs-label"
            :class="[activeTab === 'info' ? 'active' : '']"
            >活动信息</span
          >
          <DetailInfo ref="activityInfoForm" v-model="infoForm" />
        </el-tab-pane>

        <el-tab-pane label="活动准备" name="before">
          <span
            slot="label"
            class="tabs-label"
            :class="[activeTab === 'before' ? 'active' : '']"
            >活动准备</span
          >
          <DetailBefore ref="activityBeforeForm" v-model="beforeForm" />
        </el-tab-pane>
        <el-tab-pane label="会中管理" name="during">
          <span
            slot="label"
            class="tabs-label"
            :class="[activeTab === 'during' ? 'active' : '']"
            >会中管理</span
          >
          <DetailDuring
            v-if="duringForm.id"
            ref="activityDuringForm"
            v-model="duringForm"
          />
        </el-tab-pane>
        <el-tab-pane label="会后管理" name="after">
          <span
            slot="label"
            class="tabs-label"
            :class="[activeTab === 'after' ? 'active' : '']"
            >会后管理</span
          >
          <DetailAfter
            ref="activityAfterForm"
            v-if="afterForm.id"
            v-model="afterForm"
          />
        </el-tab-pane>
      </el-tabs>

      <FixedActionBar :z-index="2">
        <el-button size="medium" @click="cancelConfig">返回</el-button>

        <el-button
          type="primary"
          size="medium"
          @click="saveActivity"
          :loading="saveLoading"
        >
          {{ $route.params.type == 'add' ? '新增' : '保存' }}
        </el-button>

        <el-button
          type="primary"
          size="medium"
          @click="formSubmit('post')"
          :loading="saveLoading"
          v-if="!loading && isShowPost"
        >
          发布
        </el-button>
        <el-button
          type="danger"
          size="medium"
          @click="formWithDrow"
          :loading="saveLoading"
          v-if="!loading && !isShowPost && $route.params.type === 'edit'"
        >
          撤回
        </el-button>
      </FixedActionBar>
    </div>
    <!-- ai模块先隐藏 -->
    <!-- <div class="ai-box"></div> -->
  </div>
</template>

<script>
import {
  saveAll,
  positionLevel,
  withdraw,
  activityDetail,
  publish,
} from '@/modules/activity/api/activity-detail/detail.js'

import FixedActionBar from '@/base/layout/FixedActionBar'
import DetailOne from '@/modules/activity/views/activity-detail/detail-one.vue'
import DetailInfo from '@/modules/activity/views/activity-detail/detail-info.vue'
import DetailJoin from '@/modules/activity/views/activity-detail/detail-join.vue'
import DetailBefore from '@/modules/activity/views/activity-detail/detail-before.vue'
import DetailDuring from '@/modules/activity/views/activity-detail/detail-during.vue'
import DetailAfter from '@/modules/activity/views/activity-detail/detail-after.vue'

export default {
  components: {
    FixedActionBar,
    DetailOne,
    DetailInfo,
    DetailJoin,
    DetailBefore,
    DetailDuring,
    DetailAfter,
  },
  data() {
    return {
      loading: false,
      saveLoading: false,
      // cos服务器地址
      baseImgUrl: '',
      activeTab: 'create',
      form: {
        id: 0,
        auto_create_circle: 1,
        organization_id: [],
        type: 1,
        name: '',
        activity_end_time: '',
        activity_start_time: '',
        addresses: [],
        cover: '',
        description: `<p>活动简要</p>
                      <p>活动时间</p>
                      <p>活动地点</p>
                      <p>人数限制</p>
                      <p>着装要求</p>
                      <p>报名说明</p>
                      <p>嘉宾信息</p>
                      <p>联系方式</p>
                      <p>温馨提示</p>`,
        dress: '',
        guests: [],
        images: [],
        is_live: 0,
        is_open_tips: 0,
        is_sign: 0,
        is_stage: 0,
        join_end_time: '',
        join_limit: '',
        can_all_cycuser_join: 1,
        is_pay_dues_join: 0,
        can_visitor_join: 0,
        check_visitor_join: 1,

        // 信息收集开关
        join_form_enable: 0, //是否开启报名表单

        // 信息收集（旧）
        join_form: [],

        // 信息收集（新）
        activityForm: null,
        activity_form_id: '',

        join_positions: [],
        join_price: 0,
        join_start_time: '',
        join_type: 0,
        jump_config: {
          name: '',
          appid: '',
          url: '',
          path: '',
          roomId: '', // 小程序直播房间号
          wx_video_id: '', // 视频号ID
        },
        jump_type: 'flow',
        live_for_visitor: 0, // 直播观看权限: 游客是否可看
        live_for_user: 0, // 直播观看权限: 非会员用户是否可看
        live_pay_enable: 0, // 是否开启付费观看
        live_pay_price: 0, // 付费观看的价格
        live_pay_type: 0, // 付费观看的支付类型
        lat: '',
        lng: '',
        // live_image: "",
        live_url: '',
        number_limit: 0,
        pay_type: 0,
        plans: [],
        seat_price: 0,
        share_image: '',
        share_title: '',
        sign_explain: '',
        stage_json: [],
        units: [],
        video: {},
        view_num: 0,
        activityJoinTime: '',
        activityTime: '',
        address_limit: 0,
        limited_addresses: [],
        seat_rule: 0,
        is_info_completed: 0,
        files: [],
      },
      infoForm: {
        id: 0,
        units: [],
      },
      joinForm: {
        id: 0,
      },
      beforeForm: {
        id: 0,
      },
      duringForm: {
        id: 0,
      },
      afterForm: {
        id: 0,
      },

      // 架构列表
      positionOptions: [],
      // 是否显示发布按钮
      isShowPost: false,
      // 未修改的详情数据,需要跟新的做对比判断是否保存
      oldDetail: {},
    }
  },
  methods: {
    // tab切换逻辑
    async beforeHandle(afterName, nowName) {
      console.log(afterName, 'ee', nowName)
      console.log(this.activeTab)
      // 记录切换tab并且重新请求切换后的详情
      const callbackFun = () => {
        this.$router.push({ query: { activeTab: afterName } })
        this.getDetail(afterName)
      }
      return this.formSave(callbackFun)

      // return false
    },
    // 保存
    async formSave(callbackFun = '', isNeedSave = false) {
      // isNeedSave 必须调用保存，不判断是否一致
      if (this.activeTab === 'create') {
        return this.createSave('save', callbackFun, isNeedSave)
      } else if (this.activeTab === 'info') {
        return await this.infoSave('save', callbackFun, isNeedSave)
      } else if (this.activeTab === 'join') {
        return await this.joinSave('save', callbackFun, isNeedSave)
      } else if (this.activeTab === 'before') {
        return await this.beforeSave('save', callbackFun, isNeedSave)
      } else if (this.activeTab === 'during') {
        return await this.duringSave('save', callbackFun, isNeedSave)
      } else if (this.activeTab === 'after') {
        return await this.afterSave('save', callbackFun, isNeedSave)
      }
    },

    // 切换tab
    changeTab(e) {
      console.log('changeTab')
    },
    // 获取对应tab的详情
    getDetail(tab) {
      console.log('详情')
      this.loading = true

      activityDetail({
        id: this.$route.params.id,
        tab,
      }).then((res) => {
        console.log(res)

        if (tab === 'create') {
          let data = res.data.create

          //
          console.log(data, 'data')
          this.isShowPost = data.create_step

          if (data.images && data.images.length) {
            let arr = []
            for (let i = 0; i < data.images.length; i++) {
              /* 处理空值 */
              if (data.images[i]) arr.push(data.images[i])
            }
            data.images = arr
          } else {
            data.images = []
          }
          if (JSON.stringify(data.video) === '[]') {
            data.video = {}
          } else if (JSON.stringify(data.video).indexOf('[') === 0) {
            data.video = data.video[0]
          }
          // 为空的话返回空数组
          if (!data.join_positions) {
            data.join_positions = []
          }

          data.files =
            data.files && data.files.length
              ? data.files.map((f) => ({ ...f, name: f.title }))
              : []

          this.form = { id: this.form.id, ...data }

          // 直播没有值就默认一个值
          if (!this.form.jump_type) {
            this.form.jump_config = {
              name: '',
              appid: '',
              url: '',
              path: '',
              roomId: '', // 小程序直播房间号
            }
            this.form.jump_type = 'flow'
          }

          if (this.form.join_start_time && this.form.join_end_time) {
            this.$set(this.form, 'activityJoinTime', [
              data.join_start_time * 1000,
              data.join_end_time * 1000,
            ])
          }

          if (this.form.activity_start_time && this.form.activity_end_time) {
            this.$set(this.form, 'activityTime', [
              data.activity_start_time * 1000,
              data.activity_end_time * 1000,
            ])
          }

          // 存储旧数据
          this.oldDetail = JSON.parse(
            JSON.stringify({
              name: this.form.name,
              type: this.form.type, // 0: 线上，1:线下
              activity_start_time: this.form.activity_start_time || 0,
              activity_end_time: this.form.activity_end_time || 0,
              addresses: this.form.addresses,
              join_start_time: this.form.join_start_time || 0,
              join_end_time: this.form.join_end_time || 0,
              join_limit: this.form.join_limit, // 0 代表不限制
              organization_id: this.form.organization_id, // 组织架构 ID（混淆）
              category_ids: this.form.category_ids,
              description: this.form.description,
              video: this.form.video,
              images: this.form.images,
              files: this.form.files,
              is_live: this.form.is_live,
              jump_type: this.form.jump_type,
              jump_config: this.form.jump_config,
              live_for_visitor: this.form.live_for_visitor,
              live_for_user: this.form.live_for_user,
              live_pay_enable: this.form.live_pay_enable,
              live_pay_type: this.form.live_pay_type,
              live_pay_price: this.form.live_pay_price,
            })
          )
          console.log(this.oldDetail, '这是旧数据')
          console.log(data.preview_link, 'preview_link')
          document
            .getElementById('activityFrame')
            ?.setAttribute('src', data.preview_link)
          console.log(this.duringForm)
        } else if (tab === 'info') {
          let data = res.data.info

          //
          this.isShowPost = data.create_step

          // 活动信息
          console.log(res)

          this.infoForm = { id: this.infoForm.id, ...data }
          // 存储旧数据

          // 直播没有值就默认一个值
          if (!this.infoForm.jump_type) {
            this.infoForm.jump_config = {
              name: '',
              appid: '',
              url: '',
              path: '',
              roomId: '', // 小程序直播房间号
            }
            this.infoForm.jump_type = 'flow'
          }
          this.oldDetail = JSON.parse(JSON.stringify(this.infoForm))
        } else if (tab === 'join') {
          let data = res.data.join

          //
          this.isShowPost = data.create_step

          // 活动信息
          console.log(res)
          this.joinForm = { id: this.joinForm.id, ...data }
          if (this.joinForm.join_start_time && this.joinForm.join_end_time) {
            this.$set(this.joinForm, 'activityJoinTime', [
              data.join_start_time * 1000,
              data.join_end_time * 1000,
            ])
          }
          // 存储旧数据

          this.oldDetail = JSON.parse(JSON.stringify(this.joinForm))
        } else if (tab === 'before') {
          let data = res.data.before
          // 存储旧数据
          this.oldDetail = data

          this.isShowPost = data.create_step

          // 活动准备
          console.log(res)
          this.beforeForm = { id: this.beforeForm.id, ...data }
          // 存储旧数据

          this.oldDetail = JSON.parse(JSON.stringify(this.beforeForm))
        } else if (tab === 'during') {
          let data = res.data.during
          // 存储旧数据
          this.oldDetail = data
          //
          this.isShowPost = data.create_step

          // 会中管理
          this.duringForm = { id: res.data.id, ...data }

          this.oldDetail = JSON.parse(JSON.stringify(this.duringForm))
        } else if (tab === 'after') {
          let data = res.data.after
          // 存储旧数据
          this.oldDetail = data
          //
          this.isShowPost = data.create_step

          // 会中管理
          this.afterForm = { id: res.data.id, ...data }

          this.oldDetail = JSON.parse(JSON.stringify(this.afterForm))

          // console.log(this.afterForm)
        }
        console.log(this.isShowPost, 'this.isShowPost ')
        this.loading = false
      })
    },
    // 取消发布
    formWithDrow() {
      this.$confirm('确定要撤回此活动吗？稍后可重新发布。', '操作确认', {
        type: 'warning',
      })
        .then(() => {
          this.saveLoading = true
          withdraw({ id: this.$route.params.id })
            .then((res) => {
              this.$message.success(res.msg)
              this.getDetail(this.activeTab)
              this.saveLoading = false
            })
            .catch((err) => {
              console.log(err)
              this.saveLoading = false
            })
        })
        .catch(() => {})
    },
    saveActivity() {
      if (this.$route.params.type === 'add') {
        // 新增活动不需要回调
        this.formSave(null, true)
      } else {
        this.formSave(() => {
          this.$message.success('保存成功')
        }, true)
      }
    },
    // 发布
    formSubmit(action) {
      this.saveLoading = true

      // 保存之后再发布，回调
      const callbackFun = () => {
        publish({ id: this.$route.params.id }).then((res) => {
          // 0: 发布失败、1:发布成功
          if (!res.data.status) {
            // res.data.errors
            this.$message({
              // 将 message 就会被当作 HTML 片段处理
              dangerouslyUseHTMLString: true,
              message: res.data.errors.join('<br/><br/>'),
              type: 'error',
            })
            this.saveLoading = false
          } else {
            this.$message.success(res.msg)
            this.getDetail(this.activeTab)
            this.saveLoading = false
          }
        })
      }
      // 第一步 基本设置
      if (this.activeTab === 'create') {
        if (this.$refs.activityBaseForm.formSubmit('ActivityForm')) {
          this.createSave(action, callbackFun)
        } else {
          this.saveLoading = false
        }
      } else if (this.activeTab === 'info') {
        if (this.$refs.activityInfoForm.formSubmit('ActivityForm')) {
          this.infoSave(action, callbackFun)
        } else {
          this.saveLoading = false
        }
      } else if (this.activeTab === 'join') {
        if (this.$refs.activityJoinForm.formSubmit('ActivityForm')) {
          this.joinSave(action, callbackFun)
        } else {
          this.saveLoading = false
        }
      } else if (this.activeTab === 'before') {
        if (this.$refs.activityBeforeForm.formSubmit('ActivityForm')) {
          this.beforeSave(action, callbackFun)
        } else {
          this.saveLoading = false
        }
      } else if (this.activeTab === 'during') {
        if (this.$refs.activityBeforeForm.formSubmit('ActivityForm')) {
          this.duringSave(action, callbackFun)
        } else {
          this.saveLoading = false
        }
      } else if (this.activeTab === 'after') {
        if (this.$refs.activityBeforeForm.formSubmit('ActivityForm')) {
          this.afterSave(action, callbackFun)
        } else {
          this.saveLoading = false
        }
      }
    },
    // 取消
    cancelConfig() {
      this.$confirm('是否退出编辑活动详情?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$router.push({
            name: 'ActivityList',
          })
        })
        .catch(() => {})
    },
    // 创建活动
    createSave(action, callback, isNeedSave = false) {
      //  删除不需要上传的属性
      // this.form.addresses.forEach((item) => {
      //   this.$delete(item, 'activityTime')
      // })

      const data = {
        name: this.form.name,
        type: this.form.type, // 0: 线上，1:线下
        activity_start_time: this.form.activity_start_time || 0,
        activity_end_time: this.form.activity_end_time || 0,
        addresses: this.form.addresses,
        join_start_time: this.form.join_start_time || 0,
        join_end_time: this.form.join_end_time || 0,
        join_limit: this.form.join_limit, // 0 代表不限制
        organization_id: this.form.organization_id, // 组织架构 ID（混淆）
        category_ids: this.form.category_ids,
        description: this.form.description,
        auto_create_circle: this.form.auto_create_circle,
        video: this.form.video,
        images: this.form.images,
        files: this.form.files,
        is_live: this.form.is_live,
        jump_type: this.form.jump_type,
        jump_config: this.form.jump_config,
        live_for_visitor: this.form.live_for_visitor,
        live_for_user: this.form.live_for_user,
        live_pay_enable: this.form.live_pay_enable,
        live_pay_type: this.form.live_pay_type,
        live_pay_price: this.form.live_pay_price,
      }
      if (
        !data.join_start_time &&
        !data.join_end_time &&
        data.activity_start_time &&
        data.activity_end_time
      ) {
        let currentTimestamp = Math.floor(Date.now() / 1000)
        if (currentTimestamp < data.activity_start_time) {
          // 如果这个活动还没开始，那报名时间就是从现在 到 活动开始
          data.join_start_time = currentTimestamp
          data.join_end_time = data.activity_start_time
        } else {
          // 如果这个活动已经开始，那报名时间就是从活动开始前一天 到 活动开始时间
          data.join_start_time = data.activity_start_time - 24 * 60 * 60
          data.join_end_time = data.activity_start_time
        }
      }

      // console.log(
      //   'olddata',
      //   this.oldDetail,
      //   data,
      //   JSON.stringify(this.oldDetail) == JSON.stringify(data)
      // )
      // console.log(JSON.stringify(this.oldDetail))
      // console.log(JSON.stringify(data))

      if (!isNeedSave) {
        console.log(
          'olddata',
          this.oldDetail,
          data,
          JSON.stringify(this.oldDetail) == JSON.stringify(data)
        )
        console.log(JSON.stringify(this.oldDetail))
        console.log(JSON.stringify(data))
        if (
          !this.oldDetail ||
          JSON.stringify(this.oldDetail) == JSON.stringify(data)
        ) {
          if (callback) {
            callback()
          }
          // 都一样,不用保存
          return true
        }
      }

      data.files = data.files.map((el) => el.id)

      this.saveLoading = true

      return new Promise((resolve, reject) => {
        saveAll({
          id: this.form.id,
          action: action,
          create: data,
        })
          .then((res) => {
            console.log(res)
            // 保存成功刷新iframe
            this.refreshIframe()
            if (this.$route.params.type !== 'add') {
              this.oldDetail = data
              this.$message.success(res.msg)
              if (callback) {
                callback()
              } else {
                this.getDetail(this.activeTab)
              }
            } else {
              // this.$message.success(res.msg)
              this.$router.push({
                name: 'ActivityDetailNew',
                params: {
                  type: 'edit',
                  id: res.data.id,
                },
              })
              this.form.id = res.data.id
              setTimeout(() => {
                console.log('setTime', callback)
                if (callback) {
                  callback()
                } else {
                  console.log('setTime2')
                  this.getDetail(this.activeTab)
                }
              }, 0)
            }

            this.saveLoading = false
            resolve(true)
          })
          .catch((err) => {
            reject(false)
            this.saveLoading = false
          })
      })
    },
    // 活动信息
    infoSave(action, callback, isNeedSave = false) {
      if (!isNeedSave) {
        console.log(this.infoForm, this.oldDetail, 'olddata')

        if (
          !this.oldDetail ||
          JSON.stringify(this.oldDetail) == JSON.stringify(this.infoForm)
        ) {
          if (callback) {
            callback()
          }
          // 都一样,不用保存
          return true
        }
      }
      this.saveLoading = true
      return new Promise((resolve, reject) => {
        saveAll({
          id: this.$route.params.id,
          action: action,
          info: this.infoForm,
        })
          .then((res) => {
            console.log(res)
            if (this.$route.params.type !== 'add') {
              // this.$message.success('保存成功')
            }

            this.saveLoading = false

            resolve(true)
            if (callback) {
              callback()
            } else {
              this.getDetail(this.activeTab)
            }
          })
          .catch((err) => {
            this.saveLoading = false
            reject(false)
          })
      })
    },
    // 报名规划
    joinSave(action, callback, isNeedSave = false) {
      if (!isNeedSave) {
        if (
          !this.oldDetail ||
          JSON.stringify(this.oldDetail) == JSON.stringify(this.joinForm)
        ) {
          if (callback) {
            callback()
          }
          // 都一样,不用保存
          return true
        }
      }
      this.saveLoading = true
      return new Promise((resolve, reject) => {
        saveAll({
          id: this.$route.params.id,
          action: action,
          join: this.joinForm,
        })
          .then((res) => {
            console.log(res)
            if (this.$route.params.type !== 'add') {
              // this.$message.success('保存成功')
            }

            this.saveLoading = false

            if (callback) {
              callback()
            } else {
              this.getDetail(this.activeTab)
            }
            resolve(true)
          })
          .catch((err) => {
            this.saveLoading = false
            reject(false)
          })
      })
    },
    // 会前准备
    beforeSave(action, callback, isNeedSave = false) {
      console.log(this.beforeForm)
      let beforeData = {
        create_step: this.beforeForm.create_step,
        parking_tickets: this.beforeForm.parking_tickets,
        poster: this.beforeForm.poster,
        share: this.beforeForm.share,
        tag_info: this.beforeForm.tag_info,
        help: this.beforeForm.help,
      }

      if (!isNeedSave) {
        if (
          !this.oldDetail ||
          JSON.stringify(this.oldDetail) == JSON.stringify(this.beforeForm)
        ) {
          if (callback) {
            callback()
          }
          // 都一样,不用保存
          return true
        }
      }
      this.saveLoading = true

      return new Promise((resolve, reject) => {
        saveAll({
          id: this.$route.params.id,
          action: action,
          before: beforeData,
        })
          .then((res) => {
            console.log(res)
            if (this.$route.params.type !== 'add') {
              // this.$message.success('保存成功')
            }

            this.saveLoading = false
            resolve(true)
            if (callback) {
              callback()
            } else {
              this.getDetail(this.activeTab)
            }
          })
          .catch((err) => {
            this.saveLoading = false
            reject(false)
          })
      })
    },
    // 会中准备
    duringSave(action, callback, isNeedSave = false) {
      if (!isNeedSave) {
        if (
          !this.oldDetail ||
          JSON.stringify(this.oldDetail) == JSON.stringify(this.duringForm)
        ) {
          if (callback) {
            callback()
          }
          // 都一样,不用保存
          return true
        }
      }
      this.saveLoading = true

      return new Promise((resolve, reject) => {
        saveAll({
          id: this.$route.params.id,
          action: action,
          during: this.duringForm,
        })
          .then((res) => {
            console.log(res)
            if (this.$route.params.type !== 'add') {
              // this.$message.success('保存成功')
            }

            this.saveLoading = false
            resolve(true)
            if (callback) {
              callback()
            } else {
              this.getDetail(this.activeTab)
            }
          })
          .catch((err) => {
            this.saveLoading = false
            reject(false)
          })
      })
    },
    // 会后
    afterSave(action, callback, isNeedSave = false) {
      if (!isNeedSave) {
        if (
          !this.oldDetail ||
          JSON.stringify(this.oldDetail) == JSON.stringify(this.afterForm)
        ) {
          if (callback) {
            callback()
          }
          // 都一样,不用保存
          return true
        }
      }
      this.saveLoading = true

      return new Promise((resolve, reject) => {
        saveAll({
          id: this.$route.params.id,
          action: action,
          after: this.afterForm,
        })
          .then((res) => {
            console.log(res)
            if (this.$route.params.type !== 'add') {
              // this.$message.success('保存成功')
            }

            this.saveLoading = false
            resolve(true)
            if (callback) {
              callback()
            } else {
              this.getDetail(this.activeTab)
            }
          })
          .catch((err) => {
            this.saveLoading = false
            reject(false)
          })
      })
    },
    frameScroll(e) {
      console.log(e, '过户')
      // 阻止点击事件的默认行为
      e.preventDefault()
      // 阻止事件冒泡
      e.stopPropagation()
    },
    // 刷新iframe
    refreshIframe() {
      console.log('刷新')

      document
        .getElementById('activityFrame')
        ?.setAttribute(
          'src',
          'https://testh5.shetuan.pro/lhh#/sub/activity/pages/detail?id=XpObx2wr&pageset=release'
        )
    },
  },
  created() {
    console.log(this.$parent.$parent, 'parent')
    this.activeTab = this.$route.query.activeTab || 'create'
    this.baseImgUrl = window.serverConfig.VUE_APP_IMG_URL
    // 默认给一个详情图
    this.form.images = [`${this.baseImgUrl}/activity/activity-cover.jpg`]
    this.form.id = this.$route.params.id
    // 新活动+ 6位数随机数
    this.form.name = `新活动${
      Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000
    }`
    if (this.isEdit) {
      // 编辑
      this.getDetail(this.activeTab)
    }
  },

  beforeRouteEnter(to, from, next) {
    const type = to.params.type
    switch (type) {
      case 'recover':
        to.meta.title = '恢复活动'
        break
      case 'add':
        to.meta.title = '新增活动'
        break
      default:
        to.meta.title = '活动详情'
    }
    next()
  },
  mounted() {},
  watch: {},

  computed: {
    isEdit() {
      return this.$route.params.id && this.$route.params.id !== '0'
    },
  },
}
</script>

<style scoped lang="scss">
.body {
  min-height: calc(100vh - 190px);
  display: flex;
  .content-box {
    padding: 24px;
    border-radius: 8px;
    flex: 1;
    background-color: #fff;
    box-sizing: border-box;
    // flex-shrink:0;
    // min-width: 0;
    .tabs-label {
      color: #b3b3b3;
      &:hover {
        color: #3576ff;
      }
      &.active {
        color: #3576ff;
      }
    }
    .create-activity {
      padding: 24px;
      display: flex;
      .preview-box {
        min-width: 331px;
        height: 682px;
        padding: 15px;
        background-color: #f8f9fb;
        border: 1px dashed #dadfe6;
        border-radius: 24px;
        margin-right: 34px;
        position: relative;

        .iframe-mask {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          z-index: 2;
          background-color: rgba(#000, 0.1);
        }
      }

      .activity-form {
        flex: 1;
        // overflow-x: scroll;
      }
    }
  }
  .ai-box {
    padding: 20px;
    border-radius: 8px;
    margin-left: 20px;
    min-width: 430px;
    background-color: #fff;
  }
}

.step-box {
  margin-bottom: 32px;
  margin-top: 32px;
}
</style>
