<template>
  <div class="main-page">
    <el-form
      size="small"
      :model="ActivityForm"
      label-position="right"
      label-width="150px"
      class="form small-form"
      :rules="rules"
      v-if="ActivityForm.share"
      @submit.native.prevent
    >
      <div class="modular">
        <p class="modular-title">海报分享配置</p>
        <div class="poster-box">
          <!-- 活动海报 -->
          <div class="poster" id="activity-poster">
            <template v-if="platform">
              <!-- 预览 -->
              <PosterPreview
                v-if="!ActivityForm.poster.h5_poster_is_custom"
                scale="0.6"
                v-model="ActivityForm.poster"
                :platform="platform"
              ></PosterPreview>

              <img
                v-else-if="ActivityForm.poster.h5_poster_bg"
                :style="{ width: '100%', height: '100%' }"
                :src="ActivityForm.poster.h5_poster_bg"
              />
            </template>
            <template v-else>
              <!-- 预览 -->
              <PosterPreview
                v-if="!ActivityForm.poster.mp_poster_is_custom"
                scale="0.6"
                v-model="ActivityForm.poster"
                :platform="platform"
              ></PosterPreview>

              <img
                v-else
                :style="{ width: '100%', height: '100%' }"
                :src="ActivityForm.poster.mp_poster_bg"
              />
            </template>
          </div>
          <div class="setting">
            <div class="setting-item">
              <div class="title">平台类型</div>
              <el-radio-group v-model="platform">
                <el-radio :label="0">小程序</el-radio>
                <el-radio :label="1">H5</el-radio>
              </el-radio-group>
            </div>
            <div class="setting-item">
              <div class="title">活动入口</div>
              <div class="tips">扫码快速进入活动页</div>
              <img
                v-if="platform === 1"
                class="setting-qrcode"
                :src="ActivityForm.poster.h5_enter_qrcode"
                alt=""
              />
              <img
                v-else
                class="setting-qrcode"
                :src="ActivityForm.poster.mp_enter_qrcode"
                alt=""
              />
            </div>
            <div class="setting-item">
              <div class="title">邀请海报</div>
              <div class="tips">主办方可以通过自定义海报对用户进行邀约</div>
              <div class="button-box">
                <el-button type="primary" @click="openPosterDialog = true"
                  >编辑海报</el-button
                >
                <el-button @click="generate">下载海报</el-button>
              </div>
            </div>
            <div class="setting-item">
              <div class="title">分享海报</div>
              <div class="tips">微信扫码快速分享海报</div>
              <img
                v-if="platform === 1"
                class="setting-qrcode"
                :src="ActivityForm.poster.h5_share_qrcode"
                alt=""
              />
              <img
                v-else
                class="setting-qrcode"
                :src="ActivityForm.poster.mp_share_qrcode"
                alt=""
              />
            </div>
          </div>
        </div>
        <!-- <div
          class="modular"
          :style="{
            marginTop: '24px',
          }"
        >
          <div class="modular-content">
            <div class="qrcode-box">
              <div v-if="isWxApplet" class="qrcode-item">
                <div class="tips">小程序入口：</div>

                <img
                  class="mp_qrcode"
                  :src="ActivityForm.poster.mp_enter_qrcode"
                  alt=""
                />
              </div>
              <div class="qrcode-item">
                <div class="tips">H5 微网站入口：</div>
                <img
                  class="mp_qrcode"
                  :src="ActivityForm.poster.h5_enter_qrcode"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="modular">
        <p class="modular-title">微信分享配置</p>
        <div class="modular-content">
          <el-form-item label="分享标题：" prop="share.share_title">
            <el-input
              type="text"
              v-model="ActivityForm.share.share_title"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="分享图片：" prop="share.share_image">
            <SingleMediaWall
              v-model="ActivityForm.share.share_image"
              :width="140"
              :height="140"
              ratio="5:4"
              :show-cut="true"
            >
              <p slot="info" class="img-tips">
                建议上传尺寸比例为1500*1200，不上传图片时，默认使用系统自带图片
              </p>
            </SingleMediaWall>
          </el-form-item>
        </div>
      </div>
    </el-form>

    <PosterDialog
      v-model="ActivityForm.poster"
      :show="openPosterDialog"
      :platform="platform"
      @close="openPosterDialog = false"
    ></PosterDialog>
  </div>
</template>
<script>
import SingleMediaWall from '@/modules/common/components/SingleMediaWall.vue'
import PosterDialog from '@/modules/activity/components/activity-detail/detail-before/poster-dialog.vue'
import { toPng } from 'html-to-image'
import PosterPreview from '@/modules/activity/components/activity-detail/detail-before/poster-preview.vue'
import filesUpload from '@/base/utils/upload4'

export default {
  model: {
    prop: 'form',
    event: 'updateForm',
  },
  components: {
    SingleMediaWall,
    PosterDialog,
    PosterPreview,
  },
  props: ['form', ''],
  data() {
    return {
      platform: 0,
      openPosterDialog: false,
      rules: {},
      // 图片生成loading
      captureLoading: false,
    }
  },
  watch: {},
  created() {},
  methods: {
    /**
     * @description: 下载文件方法（仅base64）
     * @param {base64} url 下载地址
     * @param {String} fileName 文件名
     * @Date: 2021-08-19 15:39:03
     * @Author: mulingyuer
     */
    downloadURI(url, fileName = 'stage.png') {
      const link = document.createElement('a')
      link.download = fileName
      link.href = url
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      link.remove()
    },
    // 海报生成
    generate() {
      this.captureLoading = true
      const node = document.getElementById('activity-poster')

      toPng(node, {
        cacheBust: true,
        // width: 375,
        // canvasWidth: 375,
        pixelRatio: 2,
      })
        .then((url) => {
          /* 新标签页打开预览，右键保存 */
          // window.open(URL.createObjectURL(url), "_blank");
       
          let fileName = '邀请海报-' + new Date().getTime() + '.png'
          this.url = url
          // filesUpload({
          //   configApi: "/admin/admin/media/requestUpload",
          //   data: new window.File([url], fileName, {type: "image/png"}),
          // }).then((res) => {
          //   console.log(res)
          // })
          console.log(url)
          setTimeout(() => {
            this.downloadURI(url, fileName)
            this.captureLoading = false
          }, 100)
        })
        .catch((err) => {
          console.log(err)
          this.captureLoading = false
        })
    },
    // 复制链接
    handleCopyText() {
      copyText(this.ActivityForm.h5_url)
        .then((res) => {
          this.$message.success('复制成功')
        })
        .catch((err) => {
          this.$message.success('复制失败')
        })
    },
  },
  computed: {
    ActivityForm: {
      get() {
        console.log(this.form)

        return this.form
      },
      set(val) {
        this.$emit('updateForm', val)
      },
    },
    isWxApplet() {
      return this.$store.getters.tenant.features.includes('wx_applet')
    },
  },
  mounted() {},
}
</script>

<style lang="scss" scoped>
.poster-box {
  display: flex;
  .poster {
    width: 324px;
    height: 576px;
    border-radius: 8px;
    // background-color: #f1f2f3;
  }
  .setting {
    margin-left: 20px;

    .setting-item {
      .title {
        color: #25282d;
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
        margin-bottom: 8px;
      }
      .tips {
        margin-bottom: 16px;
      }
      .setting-qrcode {
        width: 130px;
        height: 130px;
      }
    }
    .setting-item + .setting-item {
      margin-top: 32px;
    }
  }
}
.qrcode-box {
  display: flex;
  align-items: center;
  .qrcode-item + .qrcode-item {
    margin-left: 80px;
  }
}
.mp_qrcode {
  width: 130px;
  height: 130px;
  vertical-align: middle;
}
.img-tips {
  text-align: center;
  font-size: 12px;
  color: #c0c4cc;
  line-height: 24px;
}

.tips {
  font-size: 12px;
  line-height: 1.3;
  margin-top: 8px;
  color: #909399;
}

.modular-content {
  margin-left: 11px;
}
</style>
