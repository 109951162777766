var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"posterBox",staticClass:"poster-box",style:({
    transform: ("scale(" + _vm.scale + ")"),
  })},[(_vm.platform)?[_c('img',{staticClass:"poster-bg",attrs:{"src":_vm.posterList[_vm.form.h5_poster_template]}})]:[_c('img',{staticClass:"poster-bg",attrs:{"src":_vm.posterList[_vm.form.mp_poster_template]}})],(_vm.platform)?_c('img',{staticClass:"logo",attrs:{"src":_vm.form.h5_icon}}):_c('img',{staticClass:"logo",attrs:{"src":_vm.form.mp_icon}}),_c('div',{staticClass:"poster-content"},[_c('img',{staticClass:"cover",attrs:{"src":_vm.form.cover}}),_c('div',{staticClass:"cover-title",style:({
        color: _vm.textColor,
      })},[_vm._v(" "+_vm._s(_vm.form.name)+" ")]),_c('div',{staticClass:"address-label",style:({
        color: _vm.textColor,
      })},[_c('i',{staticClass:"el-icon-location",style:({
          marginRight: '6px',
          color: _vm.textColor,
        })}),_vm._v("举办地址： ")]),_c('div',{staticClass:"address",style:({
        color: _vm.textColor,
      })},[_vm._v(" "+_vm._s(_vm.form.address_name)+" ")]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"time-box",style:({
        color: _vm.textColor,
      })},[_c('div',{staticClass:"date-list"},[_c('div',{staticClass:"date"},[_vm._v(" "+_vm._s(_vm.timestampToDate(_vm.form.activity_start_time))+" ")]),_c('div',{staticClass:"date"},[_vm._v(_vm._s(_vm.timestampToDate(_vm.form.activity_end_time)))])]),_c('div',{staticClass:"time-list"},[_c('div',{staticClass:"time-item-left"},[_c('div',{staticClass:"time"},[_vm._v(" "+_vm._s(_vm.timestampToTime(_vm.form.activity_start_time))+" ")])]),_c('div',{staticClass:"activity-type"}),_c('div',{staticClass:"time-item-right"},[_c('div',{staticClass:"time"},[_vm._v(" "+_vm._s(_vm.timestampToTime(_vm.form.activity_end_time))+" ")])])])])]),(_vm.platform)?[(_vm.form.h5_poster_show_qrcode)?_c('img',{staticClass:"qrcode",attrs:{"src":_vm.form.h5_share_qrcode}}):_vm._e()]:[(_vm.form.mp_poster_show_qrcode)?_c('img',{staticClass:"qrcode-weixin",attrs:{"src":_vm.form.mp_share_qrcode}}):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }